<script setup lang="ts">
import VuePdfEmbed from 'vue-pdf-embed'
import path from 'pathe'

// optional styles
import 'vue-pdf-embed/dist/styles/annotationLayer.css'
import 'vue-pdf-embed/dist/styles/textLayer.css'

defineProps<{ source: string }>()

const hasLoaded = ref(false)
const hasLoadingFailed = ref(false)

const pdfEmbed = ref<null | typeof VuePdfEmbed>(null)
async function handleDownload(source: string) {
  const pdfEmbedValue = pdfEmbed.value
  if (!pdfEmbedValue) {
    return
  }

  await navigateTo(source, { open: { target: '_blank' } })
}

const isMobileScreen = useIsMobileScreen()
</script>

<template>
  <ThePopup :show="!!source" :width="isMobileScreen ? '100%' : '60%'" :title="`PDF Ansicht: ${path.basename(source)}`">
    <div v-if="hasLoadingFailed">
      Dokument kann nicht angezeigt werden.
    </div>
    <n-skeleton v-if="!hasLoaded && !hasLoadingFailed" :repeat="40" text class="animate-pulse" />
    <FormKit v-if="hasLoaded" type="button" label="Download" @click="handleDownload(source)" />
    <VuePdfEmbed
      v-show="hasLoaded"
      ref="pdfEmbed"
      annotation-layer
      text-layer
      :source="source"
      @loaded="hasLoaded = true"
      @loading-failed="hasLoadingFailed = true"
    />
  </ThePopup>
</template>
